@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply leading-[normal] m-0;
  font-family: "Kumbh Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
::before,
::after {
  border-width: 0;
}

/* Customize scrollbar styles for WebKit-based browsers (Chrome, Safari) */
.scrollable-content::-webkit-scrollbar {
  width: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: #f6f4eb;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background-color: #f6f4eb;
}

.scrollable-content::-webkit-scrollbar-track {
  background-color: #4682a9;
}
