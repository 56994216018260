/* .video-js .vjs-tech {
  width:
} */
div[id^="vjs_video"] {
  width: 95%;
  /* height: 80%; */
}
/* Hide the seek bar */
.vjs-progress-holder {
  display: none;
}

/* Center the play/pause button */
.vjs-big-play-button {
  position: absolute;
  display: grid;
  place-items: center;
  /* top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); */
}

/* Customize play/pause button appearance */
.vjs-play-control.vjs-paused:before {
  content: "▶"; /* Play symbol */
}

.vjs-play-control.vjs-playing:before {
  content: "⏸"; /* Pause symbol */
}

/* Show big play button when paused */
.vjs-paused.vjs-has-started .vjs-big-play-button {
  display: block;
}

/* Adjust big play button position when video is clicked */
.vjs-paused.vjs-has-started.vjs-user-inactive .vjs-big-play-button {
  display: grid;
  place-items: center;
}
